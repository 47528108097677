import { Grid } from "@mui/material";
import React from "react";
import kliklogo from '../../../assets/klik_logo_colorido.png';
import "./footer.css";

const Footer = () => {

  const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();
  return (
    <>
      <section className="newletter" style={{marginTop: 50}}>
        <div className="container flexSB">
          <div className="left row">
            <h1>Solicite contato</h1>
            <span>Preencha o formulário que entraremos em contato assim que possível</span>
          </div>
          <div className="right row">
            <input type="text" placeholder="Insira seu email" />
            <i className="fa fa-paper-plane"></i>
          </div>
        </div>
      </section>
      <footer style={{ margin: 50}}>
        <Grid container spacing={2} style={{padding: 15}}>
          <Grid item xs={12} md={8}>
            <div className="box logo">
              <img src={kliklogo} alt="Logo" style={{width: 250}}/>
              <p style={{fontSize: 14, color: "#3c3c3c"}}>
              Somos uma equipe apaixonada por tecnologia, dedicada a criar softwares sob medida para atender às necessidades específicas do seu negócio. Entendemos que cada empresa é única, e é por isso que nos especializamos em desenvolver soluções personalizadas que impulsionam o seu sucesso.
              </p>

              <a href="https://instagram.com/kliklabs.tecnologia" target="_blank" ><i className='fab fa-instagram icon'></i></a>
        
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <h3>Onde estamos</h3>
            <div style={{display: "flex",alignContent: 'center', alignItems: 'center'}}>
              <i className="fa fa-map" style={{margin: 25}}></i>
              Av Costabile Romano, 2572 - Ribeirania, Ribeirão Preto - SP
            </div>
            <div style={{display: "flex",alignContent: 'center', alignItems: 'center'}}>
              <i className="fa fa-phone-alt" style={{margin: 25}}></i>
              +55 16 99777 4012
            </div>
            <div style={{display: "flex",alignContent: 'center', alignItems: 'center'}}>
              <i className="fa fa-paper-plane" style={{margin: 25}}></i>
              contato@kliklabs.com.br
            </div>
          </Grid>
        </Grid>
      </footer>
      <div className="legal">
        <p>
        Klik Labs Tecnologia LTDA 54.194.610/0001-00 | Todos os direitos reservados | Copyright ©{anoAtual} 
        </p>
      </div>
    </>
  );
};

export default Footer;
