import React from "react"
import OnlineCourses from "../allcourses/OnlineCourses"
import "../allcourses/courses.css"
import AtuacaoHome from "./atuacao/CourseHome"
import Projects from "./atuacao/projects"

const HAbout = () => {
  return (
    <>
      <section className='homeAbout'>

        <AtuacaoHome />
        <Projects />
        <OnlineCourses />
      </section>
    </>
  )
}

export default HAbout
