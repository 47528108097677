import { Grid } from '@mui/material';
import React from "react";
import { tecnologias } from "../../../dummydata";
import Heading from "../../common/heading/Heading";
import "./courses.css";
const OnlineCourses = () => {
  return (
    <>
      <section className="online">
        <div className="container">
          <Heading
            subtitle="ambientes"
          />
           <h2 style={{fontWeight: 400}}>Desenvolvimento em multi-plataformas</h2>
          <br/>
          <Grid container spacing={2}>
            {tecnologias.map((val) => {
              return (
                <Grid item xs={12} md={3} key={val.cover}>
                  <div >
                    <img src={val.cover} alt="" style={{width: 70, height: 70}}/>
                  </div>
                    <h1  style={{fontWeight: 600, fontSize: 18}}>{val.data}</h1>
                  <h3 style={{fontWeight: 400, fontSize: 16}}>{val.title}</h3>
               
                </Grid>
              );
            })}
          </Grid>
        </div>
      </section>
    </>
  );
};

export default OnlineCourses;
