import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import "./App.css"
import Footer from "./components/common/footer/Footer"
import Header from "./components/common/header/Header"
import Home from "./components/home/Home"

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          {/* <Route exact path='/about' component={About} />
          <Route exact path='/courses' component={CourseHome} />
          <Route exact path='/team' component={Team} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/journal' component={Blog} /> */}
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default App
