import React from "react"
import { homeAbout } from "../../dummydata"
import Heading from "../common/heading/Heading"
import Awrapper from "./Awrapper"
import "./about.css"

const AboutCard = () => {
  return (
    <>
      <section className='aboutHome' >
        <div style={{display : 'flex',alignContent: 'center',alignItems: 'center',justifyContent: 'center'}}>
          <div className='left row'>
            <img src='./images/about.webp' alt='' style={{width: "100%", height: 'auto'}}/>
          </div>
          <div className='right row'>
            <Heading subtitle='Agilidade e comprometimento' />
    <h2>Sempre focados em entregar excelência e qualidade</h2>
            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <div className='item flexSB' style={{display: "flex", alignContent: 'center', alignItems: "center"}}>
                    <div className='img'>
                      <img src={val.cover} alt='' style={{width: 50, height: 50}}/>
                    </div>
                    <div className='text'>
                      <h2 style={{fontSize: 16}}>{val.title}</h2>
                      <p style={{fontSize: 14, color: '#090909'}}>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default AboutCard
