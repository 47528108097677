import React from "react"
import OnlineCourses from "./OnlineCourses"

const AtuacaoHome = () => {
  return (
    <>
      <OnlineCourses />
    </>
  )
}

export default AtuacaoHome
