import { Grid } from "@mui/material";
import React from "react";
import code from '../../assets/code.png';
import coffe from '../../assets/coffe.png';
import sla from '../../assets/sla.png';

const Awrapper = () => {

  const awrapper = [
    {
      cover:  code,
      data: "+50.000",
      title: "Linhas de código",
    },
    {
      cover:  sla,
      data: "0.87%",
      title: "SLA de erro",
    },
    {
      cover:  coffe,
      data: "+10.000",
      title: "Copos de café",
    },
  ];


  return (
    <>
      <section className="awrapper">
        <Grid container spacing={2} style={{height: "100%"}}>
          {awrapper.map((val) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="img">
                  <img src={val.cover} alt="" />
                </div>
                <div className="text">
                  <h1 style={{fontSize: 30, fontWeight: 600}} >{val.data}</h1>
                  <h3 style={{fontSize: 17, fontWeight: 400}} >{val.title}</h3>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </section>
    </>
  );
};

export default Awrapper;
