import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { online } from "../../dummydata";
import Heading from "../common/heading/Heading";
import "./courses.css";

const OnlineCourses = () => {
  const [hoveredIndex, setHoveredIndex] = useState(-1); // Inicializamos com -1 para indicar que nenhuma imagem está sendo "hovered"

  const Imagem = styled.img`
    width: 100px; /* Defina a largura desejada */
    height: auto; /* Ajuste a altura automaticamente de acordo com a largura */
    filter: ${(props) => (props.hovered ? "none" : "grayscale(100%)")};
    transition: filter 0.3s ease-in-out;
    cursor: pointer;
  `;

  return (
    <>
      <section className="online" style={{ marginTop: 50 }}>
        <div className="container">
          <Heading subtitle="Linguagens" />
          <h2 style={{ fontWeight: 400 }}>
            As melhores ferramentas e frameworks do mercado
          </h2>
          <br />
          <Grid container spacing={2}>
            {online.map((val, index) => (
              <Grid item xs={4} md={1.7} key={val.cover}>
                <div>
                  <Imagem
                    src={val.cover}
                    alt={val.course}
                    hovered={index === hoveredIndex}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(-1)}
                    style={{ width: 70 }}
                  />
                </div>
                <br />
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    </>
  );
};

export default OnlineCourses;
