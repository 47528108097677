import React from "react"
import kliklogo from '../../../assets/logo_branco.png'

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
          <img src={kliklogo} alt="Logo" style={{width: 250}}/>
          </div>

          <div className='social'>         
            <a href="https://instagram.com/kliklabs.tecnologia" target="_blank" ><i className='fab fa-instagram icon'></i></a>
        
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
