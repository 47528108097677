import { Grid } from "@mui/material";
import React from "react";
import cargos from "../../../assets/cargos.png";
import ged from "../../../assets/ged.png";
import Heading from "../../common/heading/Heading";
import "./courses.css";

const Projects = () => {
  return (
    <>
      <section className="projetos" style={{ marginTop: 100 }}>
        <Heading subtitle="Explore mais sobre os projetos desenvolvidos por nossa equipe e disponíveis para comercialização." />
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              width: "90%",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <div className="img">
                <img alt="" />
              </div>
              <div className="text">
                <h1 style={{ fontSize: 24, fontWeight: 600, color: "#1EB2A6" }}>
                  GED - Gestão Eletronica de Documentos
                </h1>
                <h3 style={{ fontSize: 16, fontWeight: 400, color: "#090909" }}>
                  O Sistema GED oferece organização, segurança e busca eficiente
                  de documentos, seguindo as diretrizes da LGPD. Permita uploads
                  seguros e buscas dinâmicas para uma gestão de documentos
                  simplificada e em conformidade com a lei
                </h3>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                style={{
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={ged} alt="GED" style={{ width: "70%" }} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                style={{
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={cargos} alt="GED" style={{ width: "80%" }} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="img">
                <img alt="" />
              </div>
              <div className="text">
                <h1 style={{ fontSize: 24, fontWeight: 600, color: "#1EB2A6" }}>
                  Cargos e Salários
                </h1>
                <h3 style={{ fontSize: 16, fontWeight: 400, color: "#090909" }}>
                  <b>Em desenvolvimento</b>: Projetado para otimizar a estrutura
                  organizacional, este sistema oferece uma abordagem estratégica
                  para a definição de cargos, alinhamento salarial e progressão
                  de carreira, garantindo equidade e transparência. Aumente a
                  satisfação dos colaboradores e impulsione a performance
                  organizacional com uma solução que se adapta às suas
                  necessidades específicas.
                </h3>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default Projects;
